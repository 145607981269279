import dynamic from "next/dynamic";

const App = dynamic(() => import("../components/App"), {
  ssr: false,
});

export default function Index() {
  return <App />;
}

export async function getStaticProps() {
  return {
    props: {},
  };
}

export async function getStaticPaths() {
  return { paths: [{ params: { slug: [] } }], fallback: true };
}
